import React from 'react'
import Section from './../Section'
import SectionHeader from './../SectionHeader'
import Testimonials from './../Testimonials'
import './styles.scss'

function TestimonialsSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Testimonials
          items={[
            {
              avatar:
                'https://akute-website-static-assets.s3.amazonaws.com/marketing-site/dr-raashee-kedia-profile.png',
              name: 'Dr. Raashee Kedia, MD',
              quote:
                'I appreciate Akute Health for growing with me and being that underlying support system as I grow my practice. It has helped make things not just easier and more efficient, but also functional.',
              company: 'Health Quest MD',
            },
            {
              avatar:
                'https://akute-website-static-assets.s3.amazonaws.com/marketing-site/su-anne-hammond-profile.png',
              name: 'Dr. Su-Anne Hammond, DO',
              quote:
                'Akute Health has made the transition from being part of a big hospital to going out on my own seamless, allowing me to provide the best care for my patients.',
              company: '4Health Family Medicine',
            },
            // {
            //   avatar:
            //     'https://akute-website-static-assets.s3.amazonaws.com/jack_forbush_profile_image.png',
            //   name: 'Dr. Jack Forbush, DO',
            //   quote:
            //     'I’ve been working with AkuteHealth and must say that the developer has some fantastic ideas and plans for the product. They just released a video showing the e-prescribing integration which looks incredibly user-friendly! AkuteHealth is going to be very disruptive (in a good way!)',
            //   company: 'Osteopathic Center for Family Medicine',
            // },
            // {
            //   avatar: "https://uploads.divjoy.com/pravatar-150x-48.jpeg",
            //   name: "Shawna Murray",
            //   quote:
            //     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae, quas accusantium perferendis sapiente explicabo, corporis totam!",
            //   company: "Company"
            // },
            // {
            //   avatar: "https://uploads.divjoy.com/pravatar-150x-12.jpeg",
            //   name: "Blake Elder",
            //   quote:
            //     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae.",
            //   company: "Company"
            // }
          ]}
        />
      </div>
    </Section>
  )
}

export default TestimonialsSection
